import React from "react";

export default function Pricing() {
    return (
        <>
            <div id={"pricing"} className="xl:mx-auto xl:container py-20 2xl:px-0 px-6">
                <div className="lg:flex items-center justify-between">
                    <div className=" lg:w-1/2 w-full">
                        <p className="text-base leading-4 text-gray-600">Simple</p>
                        <h1 role="heading" className="md:text-5xl text-3xl font-bold leading-10 mt-3 text-gray-800">
                            One easy, fair price
                        </h1>
                        <p role="contentinfo" className="text-base leading-5 mt-5 text-gray-600">
                            Get the complete interactive eBook PDF with your 10-week program! This contains an interactive workbook with your routines to future-proof your back.
                        </p>
                    </div>
                    <div className="xl:w-1/2 lg:w-7/12 relative w-full lg:mt-0 mt-12 md:px-8" role="list">

                        <a href={"https://payhip.com/b/x43gp"}>
                        <div role="listitem" className="bg-white cursor-pointer shadow rounded-lg mt-3 flex relative z-30">
                            <div className="w-2.5  h-auto bg-accent rounded-tl-md rounded-bl-md" />
                            <div className="w-full p-8">
                                <div className="md:flex items-center justify-between">
                                    <h2 className="text-2xl font-semibold leading-6 text-gray-800">Digital Copy</h2>
                                    <p className="text-2xl md:mt-0 mt-4 font-semibold leading-6 text-gray-800">
                                        $10
                                    </p>

                                </div>
                                <p className="md:w-80 text-base leading-6 mt-4 text-gray-600">Digital Copy,
                                    Conquering Lower Back Pain
                                    Workbook</p>

                            </div>
                        </div>
                        </a>


                    </div>
                    <a href={"https://payhip.com/b/x43gp"}><button className={"bg-accent px-8 py-4 mx-auto my-10 hover:bg-gray-300 text-2xl rounded"}>Buy Now!</button></a>
                </div>
            </div>
        </>
    );
}
